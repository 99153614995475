<template>
  <div>
    <v-expansion-panels v-model="expansionPanels">
      <v-expansion-panel v-for="(space, spaceIndex) in form.vendor_spaces" :key="'panel-' + spaceIndex">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <template v-slot:actions>
            <v-btn fab max-width="24" max-height="24" depressed x-small color="#B3B3B3"
              ><v-icon small color="white">$expand</v-icon></v-btn
            >
          </template>
          <div class="d-flex justify-space-between">
            <!--            <div class="f-17 text&#45;&#45;primary">{{ stringifyNumber(spaceIndex+1) }} meeting space</div>-->
            <div class="f-17 text--primary">
              {{ space.name ? space.name : "Basic Information" }}
            </div>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  max-width="24"
                  v-on="on"
                  @click="removeLocationSpace(spaceIndex)"
                  max-height="24"
                  class="mr-1"
                  depressed
                  x-small
                  color="#B3B3B3"
                  ><v-icon small color="white">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Remove {{ space.name ? space.name : "Basic Information" }}</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat color="#F9F9F9">
            <v-card-text class="pb-0 mb-0">
              <v-row>
                <v-col cols="12" class="py-0 my-0">
                  <v-select
                    label="Room Category"
                    @input="setCategory(spaceIndex)"
                    v-model="space.category"
                    clearable
                    :items="categories"
                    class="py-0 my-0"
                    :rules="[getRules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-text-field
                    label="Room Name"
                    v-model="space.name"
                    clearable
                    class="py-0 my-0"
                    :rules="[getRules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-textarea label="Description" v-model="space.description" clearable class="py-0 my-0"></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0 my-0">
                  <v-textarea
                    label="Acoustic Description"
                    v-model="space.acoustic_description"
                    clearable
                    class="py-0 my-0"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="py-0 my-0">
                  <v-text-field
                    label="Floor Number"
                    :rules="[getRules.nonNegativeQuantity, getRules.number]"
                    min="0"
                    class="py-0 my-0"
                    v-model="space.floor_number"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0 my-0">
                  <v-select
                    class="py-0 my-0"
                    label="Floor type"
                    clearable
                    v-model="space.floor_type"
                    :items="floorTypes"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="py-0 my-0">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0 d-flex justify-space-between">
                      <div class="mt-1 text--primary">Outdoor</div>
                      <v-checkbox
                        class="py-0 my-0"
                        v-model="space.is_outdoor"
                        :false-value="0"
                        :true-value="1"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="d-flex justify-space-between">
                        <div class="mt-1 text--primary">Daylight</div>
                        <v-checkbox
                          class="py-0 my-0"
                          v-model="space.is_daylight"
                          :false-value="0"
                          :true-value="1"
                        ></v-checkbox>
                      </div>
                      <v-row v-if="space.is_daylight === 1">
                        <v-col cols="6">
                          <v-text-field
                            class="py-0 my-0"
                            :rules="[getRules.nonNegativeQuantity, getRules.number]"
                            min="0"
                            label="Window Width"
                            v-model="space.window_width"
                            clearable
                            suffix="m"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            class="py-0 my-0"
                            :rules="[getRules.nonNegativeQuantity, getRules.number]"
                            min="0"
                            label="Length"
                            clearable
                            v-model="space.window_length"
                            suffix="m"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" class="py-0 my-0">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="d-flex justify-space-between">
                        <div class="mt-1 text--primary">Parking space</div>
                        <v-checkbox
                          class="py-0 my-0"
                          v-model="space.has_parking_space"
                          :false-value="0"
                          :true-value="1"
                        ></v-checkbox>
                      </div>
                      <div v-if="space.has_parking_space === 1">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[getRules.nonNegativeQuantity, getRules.number]"
                          min="0"
                          v-model="space.parking_slots"
                          label="Parking slots"
                          clearable
                        ></v-text-field>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <div class="mt-3 mb-5 text--primary d-flex justify-space-between">
                    <span>Size</span>
                    <v-radio-group v-model="space.size_type" row class="mt-0">
                      <v-radio label="Area" value="area"></v-radio>
                      <v-radio label="Measurements" value="measurements"></v-radio>
                    </v-radio-group>
                  </div>
                  <v-row v-if="space.size_type == 'measurements'">
                    <v-col cols="6" class="py-0 my-0">
                      <v-text-field
                        class="py-0 my-0 mb-2"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        label="Length"
                        @input="calculateRoomSize(spaceIndex)"
                        v-model="space.length"
                        clearable
                        suffix="m"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0 my-0 mb-2">
                      <v-text-field
                        class="py-0 my-0"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        label="Width"
                        @input="calculateRoomSize(spaceIndex)"
                        clearable
                        v-model="space.width"
                        suffix="m"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0 my-0">
                      <v-text-field
                        class="py-0 my-0"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        label="Height"
                        clearable
                        v-model="space.height"
                        suffix="m"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0 my-0">
                      <v-text-field
                        class="py-0 my-0"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        label="Room size (calculated)"
                        disabled
                        readonly
                        v-model="space.size"
                        suffix="sqm"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="py-0 my-0">
                      <v-text-field
                        class="py-0 my-0"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        label="Room size (calculated)"
                        v-model="space.size"
                        suffix="sqm"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="py-0 my-0">
                  <div class="mt-5 mb-3 text--primary">Capacities</div>

                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="d-flex justify-space-between">
                        <div class="mt-1 text--primary">
                          <v-icon color="primary" class="mr-2">mli-standing-capacity</v-icon>
                          Standing Capacity
                        </div>
                        <v-checkbox
                          class="py-0 my-0"
                          v-model="space.is_standing_capacity"
                          :false-value="0"
                          :true-value="1"
                        ></v-checkbox>
                      </div>
                      <v-text-field
                        v-if="space.is_standing_capacity === 1"
                        class="py-0 my-0"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        label="Maximum Capacity"
                        v-model="space.standing_max_capacity"
                        clearable
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="py-0 my-0 d-flex justify-space-between">
                        <div class="text--primary">
                          <v-icon color="primary" class="mr-2">mli-u-shape</v-icon>
                          U-Shape
                        </div>
                        <div>
                          <v-checkbox
                            class="py-0 my-0"
                            v-model="space.is_u_shape"
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <v-text-field
                        v-if="space.is_u_shape === 1"
                        class="py-0 my-0"
                        v-model="space.u_shape_max_capacity"
                        clearable
                        label="Maximum capacity"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="py-0 my-0 d-flex justify-space-between">
                        <div class="text--primary">
                          <v-icon color="primary" class="mr-2">mli-cocktail-rounds</v-icon>
                          Cocktail Rounds
                        </div>
                        <div>
                          <v-checkbox
                            class="py-0 my-0"
                            v-model="space.is_cocktail_rounds"
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <v-text-field
                        v-if="space.is_cocktail_rounds === 1"
                        class="py-0 my-0"
                        v-model="space.cocktail_rounds_max_capacity"
                        clearable
                        label="Maximum capacity"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="py-0 my-0 d-flex justify-space-between">
                        <div class="text--primary">
                          <v-icon color="primary" class="mr-2">mli-banquet-rounds</v-icon>
                          Banquet Rounds
                        </div>
                        <div>
                          <v-checkbox
                            class="py-0 my-0"
                            v-model="space.is_banquet_rounds"
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <v-text-field
                        v-if="space.is_banquet_rounds === 1"
                        class="py-0 my-0"
                        v-model="space.banquet_rounds_max_capacity"
                        clearable
                        label="Maximum capacity"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="py-0 my-0 d-flex justify-space-between">
                        <div class="text--primary">
                          <v-icon color="primary" class="mr-2">mli-theater</v-icon>
                          Theater
                        </div>
                        <div>
                          <v-checkbox
                            class="py-0 my-0"
                            v-model="space.is_theater"
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <v-text-field
                        v-if="space.is_theater === 1"
                        class="py-0 my-0"
                        v-model="space.theater_max_capacity"
                        clearable
                        label="Maximum capacity"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="py-0 my-0 d-flex justify-space-between">
                        <div class="text--primary">
                          <v-icon color="primary" class="mr-2">mli-classroom</v-icon>
                          Classroom
                        </div>
                        <div>
                          <v-checkbox
                            class="py-0 my-0"
                            v-model="space.is_classroom"
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <v-text-field
                        v-if="space.is_classroom === 1"
                        class="py-0 my-0"
                        v-model="space.classroom_max_capacity"
                        clearable
                        label="Maximum capacity"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pb-0 mb-0">
                      <div class="py-0 my-0 d-flex justify-space-between">
                        <div class="text--primary">
                          <v-icon color="primary" class="mr-2">mli-boardroom</v-icon>
                          Boardroom
                        </div>
                        <div>
                          <v-checkbox
                            class="py-0 my-0"
                            v-model="space.is_boardroom"
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </div>
                      </div>
                      <v-text-field
                        v-if="space.is_boardroom === 1"
                        class="py-0 my-0"
                        v-model="space.boardroom_max_capacity"
                        clearable
                        label="Maximum capacity"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <div class="text--primary">Amenities</div>
                  <v-combobox
                    label="Amenities"
                    :items="amenities"
                    v-model="space.amenities"
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    multiple
                  >
                    <!--                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-bind="attrs" :input-value="selected" label small>
                        <span class="pr-2">{{ item }}</span>
                        <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item, on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <div class="pt-5 pb-0 d-flex">
                          <v-checkbox class="py-0 my-0" :value="checkAmenities(space.amenities, item)" :true-value="1" :false-value="0"></v-checkbox>
                          <div class="py-0 my-0">{{item}}</div>
                        </div>
                      </div>
                    </template>-->
                  </v-combobox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="mt-7">
      <v-btn
        depressed
        class="text-uppercase f-15 btn-add-space"
        color="#EDEDED"
        @click="addSpace"
        height="46"
        width="100%"
        >+ Add Meeting Space</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["form"],
  data: () => ({
    categories: [
      "Bar",
      "Lounge",
      "Beach Club",
      "Pool",
      "Rooftop Venue",
      "Adventure Event Venue",
      "Event Venue",
      "Ship",
      "Yacht",
      "Ballroom",
      "Film",
      "Photo",
      "Recording Studio",
      "Exhibition Hall",
      "Industry Hall",
      "Outdoor Venue",
      "Restaurant",
      "Club",
      "Cinema",
      "Pool",
      "Meeting Room",
      "Conference Room",
      "Theater",
      "Arena"
    ],
    floorTypes: ["Parquet", "Tiled", "Carpet", "Stone", "Other"],
    amenities: [
      "Free Wifi",
      "Gift Shop",
      "Spa",
      "Pet Friendly",
      "Pool",
      "Airport Shuttles",
      "Rent-a-car",
      "Rent-a-bike",
      "Sport Area",
      "Gym",
      "Beauty Salon"
    ],
    expansionPanels: null
  }),
  computed: {
    ...mapGetters(["getNewVendorSpace", "getRules"]),
    mapAmenities: {
      get(amenities) {
        return this.amenities;
      },
      set(value) {
        this.setFilters({ rooms: value });
      }
    }
  },
  methods: {
    calculateRoomSize(spaceIndex) {
      this.form.vendor_spaces[spaceIndex].size =
        this.form.vendor_spaces[spaceIndex].length * this.form.vendor_spaces[spaceIndex].width;
    },
    checkAmenities(amenities, item) {
      let isChecked = 0;
      if (amenities) {
        if (amenities.includes(item)) {
          isChecked = 1;
        }
      }
      return isChecked;
    },
    setCategory(spaceIndex) {
      this.form.vendor_spaces[spaceIndex].name = this.form.vendor_spaces[spaceIndex].category;
    },
    addSpace() {
      this.form.vendor_spaces.push(new Form(this.getNewVendorSpace));
      this.openExpansionPanel();
    },
    openExpansionPanel() {
      this.expansionPanels = this.form.vendor_spaces.length - 1;
    },
    removeLocationSpace(index) {
      this.form.vendor_spaces = _.reject(this.form.vendor_spaces, (locationSpace, locationSpaceIndex) => {
        return locationSpaceIndex === index;
      });
    }
  }
};
</script>
<style scoped>
.v-expansion-panel {
  background-color: #f9f9f9 !important;
}
.theme--light.v-card {
  background-color: #f9f9f9 !important;
}
.btn-add-space {
  color: #b3b3b3;
}
</style>
